import React from 'react';
import './InformationDisplay.css';

function InformationDisplay() {
  return (
    <div className="info-container">
      <div className="info-content">
        <h2>Contact Information</h2>
        <div className="info-item">
          <span>Email:</span>
          <a href="mailto:doniechemical@gmail.com">ngongothetrading@gmail.com</a>
        </div>
        <div className="info-item">
          <span>Address:</span>
          <p>36 Merriman Ave, Vereeniging, 1930</p>
        </div>
        <div className="info-item">
          <span>Contact:</span>
          <p>Abongile - 0813677320</p>
        </div>
      </div>
    </div>
  );
}

export default InformationDisplay;
