import image_1 from './section_01.jpg'
import image_2 from './image_2.png'

const data = [
    {
      header: 'About Ngongothe Trading',
      body:
        'Ngongothe Trading is a proudly South African company that was established with the advent of democracy in the country. With an extensive customer base spanning across Africa, Ngongothe Trading specializes in the supply of medical goods to institutions and patients, as well as the exclusive distribution of medicines to registered vendors.',
      imageUrl: image_1,
      textColor: 'white', // Replace with your desired text color
      backgroundColor: '#454851', // Replace with your desired background color
    },
    {
      header: 'Discover Our Products',
      body:
        "At Ngongothe Trading, we take pride in offering a diverse range of services tailored to meet various needs. Our offerings include providing adult nappies for individual patients, delivering medical equipment to large companies and hospitals, and even offering complete refurbishment services for units and wards. We are committed to providing top-quality service, and our efficient delivery system and reliable courier services ensure that we meet your requirements promptly. Serving customers across South Africa and Southern Africa, we strive to be your trusted partner in healthcare.",
      imageUrl: image_2,
      textColor: 'white', // Replace with your desired text color
      backgroundColor: '#454851', // Replace with your desired background color
    },
];

export default data;
